import home from './home';

import essac_supervisorycompanies from './essac/supervisorycompanies';
import essac_contractingcompanies from './essac/contractingcompanies';
import essac_students from './essac/students';
import essac_questions from './essac/questions';
import essac_courses from './essac/courses';
import essac_reports from './essac/reports';

import supervisors_reports from './supervisors/reports';

import contractors_students from './contractors/students';
import contractors_courses from './contractors/courses';
import contractors_reports from './contractors/reports';

import students_courses from './students/courses';

export default [
    ...home,
    ...essac_supervisorycompanies,
    ...essac_contractingcompanies,
    ...essac_students,
    ...essac_questions,
    ...essac_courses,
    ...essac_reports,

    ...supervisors_reports,

    ...contractors_students,
    ...contractors_courses,
    ...contractors_reports,

    ...students_courses
];