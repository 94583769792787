import { actions, resources } from '@/libs/acl/resources';

export default [{
    title: 'Preguntas',
    key: 'essac_questions',
    icon: 'HelpCircleIcon',
    children: [
        {
            title: 'Lista',
            route: 'questions-list',
            resource: resources.ESSAC_QUESTIONS,
        },
        {
            title: 'Agregar',
            route: 'questions-create',
            resource: resources.ESSAC_QUESTIONS,
            action: actions.CREATE
        }
    ]
}];