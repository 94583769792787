import { resources } from '@/libs/acl/resources';

export default [{
	title: 'Servicios',
	key: 'contractors_courses',
	icon: 'BookIcon',
	children: [
		{
			title: 'Lista',
			route: 'contractors-courses-list',
			resource: resources.CONTRACTOR_COURSES
		}
	]
}];
