import { resources } from '@/libs/acl/resources';

export default [{
    title: 'Empresas contratistas',
    key: 'essac_contracting_companies',
    icon: 'BriefcaseIcon',
    children: [
        {
            title: 'Lista',
            route: 'contracting-companies-list',
            resource: resources.ESSAC_CONTRACTING_COMPANIES,
        },
        {
            title: 'Contactos',
            route: 'contractors-list',
            resource: resources.ESSAC_CONTRACTING_COMPANIES
        }
    ]
}];