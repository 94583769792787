import { resources } from '@/libs/acl/resources';

export default [{
	title: 'Servicios',
	key: 'students_courses',
	icon: 'BookIcon',
	children: [
		{
			title: 'Lista',
			route: 'students-courses-list',
			resource: resources.STUDENT_COURSES
		}
	]
}];
