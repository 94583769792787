import { actions, resources } from '@/libs/acl/resources';

export default [{
	title: 'Servicios',
	key: 'essac_courses',
	icon: 'BookIcon',
	children: [
		{
			title: 'Lista',
			route: 'courses-list',
			resource: resources.ESSAC_COURSES
		},
		{
			title: 'Agregar',
			route: 'courses-create',
			resource: resources.ESSAC_COURSES,
			action: actions.CREATE
		}
	]
}];
