import { resources } from '@/libs/acl/resources';

export default [{
    title: 'Personal',
    key: 'contractors_students',
    icon: 'SmileIcon',
    children: [
        {
            title: 'Lista',
            route: 'contractors-students-list',
            resource: resources.CONTRACTOR_STUDENTS
        }
    ]
}];
